import axios, { AxiosRequestConfig } from 'axios'
import api from './axiosInstance'

export interface IFetchApi<T> {
  data: T | null
  error: string | null
}

export type IFetchApiFormData = {
  key: string
  value?: any
}

async function RequestApi<T>(
  url: string,
  options: AxiosRequestConfig,
): Promise<IFetchApi<T>> {
  try {
    const res = await api({ url, ...options })

    if (res.status >= 200 && res.status < 400) {
      return { data: res.data, error: null }
    } else if (res.status >= 400 && res.status < 500) {
      return { data: null, error: res.data.error }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        data: null,
        error: error.message,
      }
    } else {
      return {
        data: null,
        error: 'Erro desconhecido.',
      }
    }
  }

  return {
    data: null,
    error: 'Sem dados.',
  }
}

export async function ApiGet<T>(
  endpoint: string,
  params?: any,
): Promise<IFetchApi<T>> {
  return await RequestApi(endpoint, {
    method: 'get',
    params,
  })
}

export async function ApiPost<T>(
  endpoint: string,
  data?: any,
  timeout?: number,
): Promise<IFetchApi<T>> {
  return await RequestApi(endpoint, {
    method: 'post',
    data,
    timeout,
  })
}

export async function ApiPostFormData<T>(
  endpoint: string,
  data: IFetchApiFormData[],
  timeout?: number,
): Promise<IFetchApi<T>> {
  const formData = new FormData()

  data.forEach((item) => {
    formData.append(item.key, item.value)
  })

  return await RequestApi(endpoint, {
    method: 'post',
    data: formData,
    timeout,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function ApiPut<T>(
  endpoint: string,
  data?: any,
): Promise<IFetchApi<T>> {
  return await RequestApi(endpoint, {
    method: 'put',
    data,
  })
}

export async function ApiPatch<T>(
  endpoint: string,
  data?: any,
): Promise<IFetchApi<T>> {
  return await RequestApi(endpoint, {
    method: 'patch',
    data,
  })
}

export async function ApiDelete<T>(
  endpoint: string,
  params?: any,
): Promise<IFetchApi<T>> {
  return await RequestApi(endpoint, {
    method: 'delete',
    params,
  })
}
