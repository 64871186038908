import axios from 'axios'

const getURLAPI = () => {
  if (process.env!.NODE_ENV === 'production') {
    return 'https://sign.aptosistemas.com.br'
  } else if (process.env!.NODE_ENV === 'development') {
    return 'http://localhost:4008'
  } else {
    return 'http://localhost:4008'
  }
}

const api = axios.create({
  baseURL: getURLAPI(),
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus(status) {
    return status < 500
  },
  withCredentials: true,
})

export default api
