import React, { useState } from 'react'
import useController from '../controllers/sign/Auth.Login.Controller'
import { User } from '../types'
import { useLocation, useNavigate } from 'react-router-dom'

type IUserContext = {
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
  user: User | null
  loading: boolean
  login: (email: string, password: string) => void
  logout: () => void
  checkLogin: () => Promise<boolean>
}

const UserContext = React.createContext<IUserContext | null>(null)

export const useUser = () => {
  const context = React.useContext(UserContext)
  if (!context) throw new Error('useUser precisa estar em UserContextProvider')
  return context
}

export const UserContextProvider = ({ children }: React.PropsWithChildren) => {
  const controller = useController()
  const [error, setError] = useState<string | null>('')
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const checkLogin = async () => {
    const { data, error } = await controller.CheckLogin()
    setUser(data)
    return error === null
  }

  const login = async (email: string, password: string) => {
    setLoading(true)
    const { data, error } = await controller.Login({ email, password })
    setLoading(false)
    setError(error)
    if (data) {
      setUser({
        user: {
          name: data.user.name,
          hitToken: data.user.hitToken,
        },
      })

      const origin = location.state?.from?.pathname || '/'
      navigate(origin)
    }
  }
  const logout = async () => {
    await controller.Logout()
    setUser(null)
    navigate('/auth/login')
  }

  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        error,
        setError,
        user,
        checkLogin,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
