import { ApiGet, ApiPost } from '../../hooks/sign/useApi'
import { User } from '../../types'

export default () => {
  return {
    Login: async (params?: object) => {
      return await ApiPost<User>('/auth/login', params)
    },
    CheckLogin: async (params?: object) => {
      return await ApiGet<User>('/auth/validate', params)
    },
    Logout: async () => {
      return await ApiPost('/auth/logout', {})
    },
  }
}
