import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import ProtectRoute from './middlewares/RoutesProtect'
import { UserContextProvider } from './context/UserContext'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const Login = React.lazy(() => import('./views/login/Login'))
const Logout = React.lazy(() => import('./views/logout/Logout'))

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

class App extends Component {
  render(): JSX.Element {
    return (
      <BrowserRouter>
        <UserContextProvider>
          <Suspense fallback={<CSpinner color="primary" />}>
            <ToastContainer />
            <Routes>
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/logout" element={<Logout />} />
              <Route element={<ProtectRoute />}>
                <Route path="/*" element={<DefaultLayout />} />
              </Route>
            </Routes>
          </Suspense>
        </UserContextProvider>
      </BrowserRouter>
    )
  }
}

export default App
