import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'

const ProtectRoute = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { checkLogin } = useUser()

  const checkAuth = async () => {
    const res = await checkLogin()
    if (!res) {
      navigate('/auth/login', { replace: true, state: { from: location } })
    }
  }

  useEffect(() => {
    const execute = async () => {
      await checkAuth()
    }
    execute()
  }, [location.pathname])

  return <Outlet />
}

export default ProtectRoute
